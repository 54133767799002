import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'

const error404 = () => (
  <>
    <Helmet>
      <meta charSet="iso-8859-1" />
      <title>AZNAP - ilyen oldal itt nincs</title>
    </Helmet>
    <div id="container" className="TkachenkoSketch4F-bold-normal">
      <p>&nbsp;</p>
      <p>Ilyen oldal itt nincs</p>
      <p>&nbsp;</p>
      <p><Link to="/">menj vissza</Link></p>
    </div>
  </>
)

export default error404